// Override default variables before the import
$body-bg: #FFFAFB
$primary: #e58c18
$secondary : #343879
$light: #FFF
$success: #1CC879
$span-color: #A3A3A3

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap')
$font-family-sans-serif: "Rubik", sans-serif

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss'